<template>
  <BaseButton
    id="picto"
    size="xl"
    nude
    class="flex flex-col gap-2 md:py-2 md:px-6 cursor-pointer h-auto"
    @click="$emit('click')"
  >
    <Icon
      :icon="icon"
      :size="size"
      :color="color"
      :action="actionIcon"
      class="transition-all ease-in-out"
      :action-color="actionColor"
      :action-size="actionSize"
      main-class="group-hover:scale-[1.02] drop-shadow-sm transition-all scale-100 w-14 h-14"
      :action-class="iconActionClass"
    />
    <span
      class="font-weight-normal"
      :class="textClass"
      style="white-space: pre"
    >
      <slot />
    </span>
  </BaseButton>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import BaseButton from '@/components/button/BaseButton.vue';
import Icon from '@/components/icon/Icon.vue';

// Define the types for injected properties
const $isMobile = inject<boolean>('$isMobile');
const $isDesktop = inject<boolean>('$isDesktop');

// Define the props interface
interface Props {
  icon: string;
  color?: string;
  actionIcon?: string;
  actionColor?: string;
  actionClass?: string | null;
  iconSize?: string;
  actionIconSize?: string;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'click'): void;
}>();

const iconActionClass = computed<string>(() => {
  return `group-hover:scale-110 transition-all scale-100 group-hover:delay-100 group-hover:drop-shadow-md ${props.actionClass || ''}`;
});

const size = computed<string>(() => 
  props.iconSize ?? ($isDesktop ? 'xxl' : 'xl')
);
const actionSize = computed<string>(() => 
  props.actionIconSize ?? ($isMobile ? 'md' : 'lg')
);
const fontSize = computed<string>(() => ($isMobile ? 'text-xs' : 'text-sm'));
const lineHeight = computed<string>(() => ($isMobile ? 'leading-none' : 'leading-normal'));
const textClass = computed<string[]>(() => [fontSize.value, lineHeight.value]);
</script>
