<template>
  <VTooltip
    :data-cy="`tooltip-${id}`"
    :placement="position" 
    :disabled="!message && !image"
  >
    <slot />
    <Icon
      v-if="icon"
      :icon="icon"
      :color="color"
      class="tooltip-icon text-skin-accent"
    />
    <template #popper>
      <slot name="message">
        <template v-if="message">
          <img
            v-if="image"
            :src="image.url"
            :width="image.width"
            height="auto"
            :alt="$t(message)"
            class="tooltip-message-img"
            :class="tooltipContentClass"
          />
          <span
            v-else
            class="tooltip-message-text"
            :class="tooltipContentClass"
            v-html="$t(message)"
          />
        </template>
      </slot>
    </template>
  </VTooltip>
</template>

<script setup>
import { computed } from 'vue';
import Icon from '@/components/icon/Icon.vue';

const props = defineProps({
  id: {
    type: String,
    default: () => `id_${Math.floor(Math.random() * 10000)}`,
  },
  icon: String,
  color: String,
  message: String,
  position: { type: String, default: 'top' },
  align: { type: String, default: 'left' },
  triangle: { type: Boolean, default: true },
  image: Object,
});

const tooltipContentClass = computed(() => {
  return 'bg-skin-ui border-skin-ui whitespace-pre-line';
});
</script>
